import React from "react";
import { Container, Table } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { HashLink } from "react-router-hash-link";
import powder from "../../assets/powder.png";

function SuvaCity() {
  const phoneNumber = "2430800";

  const handleCallButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  }
  return (
    <div id="contact">
      <header className="App-header">
        <br></br>
      <Typography
              variant="h4"
              align="center"
              sx={{
                mb: 4,
                fontFamily: 'cursive',
                fontSize: '2.5rem',
                color: '#fff', // Set the color to white

              }}
            >
              Suva City
       </Typography>
        <Container>
          <br></br>
        <h3>Waxing Services</h3>
          <Table striped bordered hover>         
            <thead>
              <tr>
                <th>Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Brow Wax</td>
                <td>$8.00</td>
              </tr>
              <tr>
                <td>Lip (Upper/Lower) Wax</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td>Chin Wax</td>
                <td>from $6.00</td>
              </tr>
              <tr>
                <td>Sides Wax</td>
                <td>from $8.00</td>
              </tr>
              <tr>
                <td>Forehead Wax</td>
                <td>from $8.00</td>
              </tr>
              <tr>
                <td>Full Face Wax</td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <td>Full Face/Neck Wax</td>
                <td>from $35.00</td>
              </tr>
              <tr>
                <td>Underarms Wax</td>
                <td>from $20.00</td>
              </tr>
              <tr>
                <td>1/2 Arms Wax</td>
                <td>from $25.00</td>
              </tr>
              <tr>
                <td>Full Arms Wax</td>
                <td>from $35.00</td>
              </tr>
              <tr>
                <td>Nipple Wax</td>
                <td>from $15.00</td>
              </tr>
              <tr>
                <td>Chest Wax</td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <td>Stomach Wax</td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <td>Full Back Wax</td>
                <td>from $60.00</td>
              </tr>
              <tr>
                <td>Buttocks Wax</td>
                <td>from $35.00</td>
              </tr>
              <tr>
                <td>Bikini Wax</td>
                <td>from $45.00</td>
              </tr>
              <tr>
                <td>Brazilian Wax</td>
                <td>from $55.00</td>
              </tr>
              <tr>
                <td>1/2 Leg Wax</td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <td>3/4 Leg Wax</td>
                <td>from $40.00</td>
              </tr>
              <tr>
                <td>Thighs Wax</td>
                <td>from $40.00</td>
              </tr>
              <tr>
                <td>Full Leg Wax</td>
                <td>from $55.00</td>
              </tr>
            </tbody>
          </Table>
        </Container>

        <div class="container">
        <br></br>
        <h3>Eyelashes</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead class="thead-dark">
              <tr>
                <th>Services</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Classic – Single Lashes<br />
                  <span style={{ fontSize: "smaller" }}>
                    Uses single/individual custom sized lashes to give a natural classic look, very light & comfortable
                  </span>
                </td>
                <td>1 Hour</td>
                <td>$65.00</td>
              </tr>
              <tr>
                <td>Natural<br />
                  <span style={{ fontSize: "smaller" }}>
                    Uses 2D lashes to give a natural and thick look. Comfortable
                  </span></td>
                <td>45 minutes</td>
                <td>$55.00</td>
              </tr>
              <tr>
                <td>Light Hybrid<br />
                  <span style={{ fontSize: "smaller" }}>
                    Uses 3-4D lashes to give a light medium look. Comfortable
                  </span></td>
                <td>45 minutes</td>
                <td>$65.00</td>
              </tr>
              <tr>
                <td>Hybrid<br />
                  <span style={{ fontSize: "smaller" }}>
                    Uses 4-6D medium lashes to give a thick medium volume look.
                  </span></td>
                <td>1 Hour</td>
                <td>$80.00</td>
              </tr>
              <tr>
                <td>Volume<br />
                  <span style={{ fontSize: "smaller" }}>
                    Uses 6-10D medium to long lashes to give a thick and volume look.
                  </span></td>
                <td>1 Hour</td>
                <td>$100.00-$120.00</td>
              </tr>
              <tr>
                <td>Mega Volume<br />
                  <span style={{ fontSize: "smaller" }}>
                    Uses 6-10D extra-long lashes to give a thick and volume look.
                  </span></td>
                <td>1 Hour</td>
                <td>$120.00-$150.00</td>
              </tr>
              <tr>
                <td>Infills</td>
                <td>1 week</td>
                <td>from $50.00</td>
              </tr>
              <tr>
                <td>Infills</td>
                <td>2 weeks</td>
                <td>from $65.00</td>
              </tr>
              <tr>
                <td>Infills</td>
                <td>3 weeks</td>
                <td>from $75.00</td>
              </tr>
              <tr>
                <td>Lash Removal</td>
                <td>30 mins</td>
                <td>from $30.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>Threading</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Services</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Brow</td>
                <td>$7.00</td>
              </tr>
              <tr>
                <td>Lip</td>
                <td>$3.00</td>
              </tr>
              <tr>
                <td>Chin</td>
                <td>from $4.00</td>
              </tr>
              <tr>
                <td>Full Face</td>
                <td>from $25.00</td>
              </tr>
              <tr>
                <td>Full Face/Neck</td>
                <td>from $30.00</td>
              </tr>
            </tbody>
          </table>

          <br />
          <hr style={{ border: "2px dashed #000", width: "100%" }} />
          <br />


          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Services</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Face Bleach</td>
                <td>15 mins</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>Eyebrow Tinting Only</td>
                <td>15 mins</td>
                <td>$10.00</td>
              </tr>
              <tr>
                <td>Eyebrow Thread & Tint</td>
                <td>15 mins</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>Eyebrow Wax & Tint</td>
                <td>15 mins</td>
                <td>$18.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
        <br></br>
        <h3>Gel Polish Manicure</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Services</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>File Buff & Polish</td>
                <td>30 mins</td>
                <td>from $20.00 to $30.00</td>
              </tr>
              <tr>
                <td>Gel Polish French Natural-Nail File Buff & Polish</td>
                <td>45 mins</td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <td>Gel Polish Removal Buff Only</td>
                <td>10 mins</td>
                <td>$10.00</td>
              </tr>
              <tr>
                <td>Gel Polish Removal Soak off</td>
                <td>20 mins</td>
                <td>$15.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container my-5">
         <h3>Gel Polish Pedicure</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">Services</th>
                <th scope="col">Duration</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>File Buff & Polish</td>
                <td>30 mins</td>
                <td>From $25.00 to $30.00</td>
              </tr>
              <tr>
                <td>Gel Polish French Natural-Nail File Buff & Polish</td>
                <td>45 mins</td>
                <td>from $35.00</td>
              </tr>
              <tr>
                <td>Gel Polish Removal Buff Only</td>
                <td>10 mins</td>
                <td>$10.00</td>
              </tr>
              <tr>
                <td>Gel Polish Removal Soak off</td>
                <td>20 mins</td>
                <td>$15.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container">
          <h3>Extras</h3>
          <table class="table table-bordered table-striped table-hover">
            <tbody>
              <tr>
                <td>Nail Art Free Hand</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Stickers per nail</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Rhinestones per nail</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Encapsulated Art per nail</td>
                <td>From $3.00</td>
              </tr>
              <tr>
                <td>Glitter per nail</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Nail Repair (Gel)</td>
                <td>From $5.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container">
          <br></br>
          <h3>Acrylic Manicure Services</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Acrylic Extensions French</td>
                <td>45 mins</td>
                <td>From $45.00 to $55.00</td>
              </tr>
              <tr>
                <td>Acrylic Extension & Gel Polish</td>
                <td>1 hour 30 mins</td>
                <td>From $55.00</td>
              </tr>
              <tr>
                <td>Acrylic Overlay & Gel Polish - Short</td>
                <td>1 hour</td>
                <td>From $45.00</td>
              </tr>
              <tr>
                <td>Acrylic Overlay & Gel Polish – Long</td>
                <td>1 hour</td>
                <td>From $50.00 to $55.00</td>
              </tr>
              <tr>
                <td>Infill Acrylic Extensions & Gel Polish</td>
                <td>1 hour</td>
                <td>From $45.00</td>
              </tr>
              <tr>
                <td>Infill Acrylic Overlay & Gel Polish</td>
                <td>45 mins</td>
                <td>From $35.00 to $40.00</td>
              </tr>
              <tr>
                <td>Acrylic Removal Soak Off</td>
                <td>45 mins</td>
                <td>$30.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>Extras</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nail Shape per set (Coffin, Stiletto, Almond, Oval)</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td colSpan="2" style={{ textAlign: "left", fontWeight: "bold" }}>Length per set</td>
              </tr>
              <tr>
                <td>Medium to Long Length</td>
                <td>$5.00-$15.00</td>
              </tr>
              <tr>
                <td>Extra Long Length</td>
                <td>$20.00</td>
              </tr>
              <tr>
                <td>Nail Art Free Hand</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Stickers per nail</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Rhinestones per nail</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Encapsulated Art per nail</td>
                <td>from $3.00</td>
              </tr>
              <tr>
                <td>Glitter per nail</td>
                <td>from $3.00</td>
              </tr>
              <tr>
                <td>Nail Repair (Acrylic)</td>
                <td>from $10.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>Acrylic Pedicure</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Acrylic Extensions French</td>
                <td>45 minutes</td>
                <td>from $60.00</td>
              </tr>
              <tr>
                <td>Acrylic Extension & Gel Polish</td>
                <td>1 hour 30 minutes</td>
                <td>from $70.00</td>
              </tr>
              <tr>
                <td>Acrylic Overlay & Gel Polish</td>
                <td>1 hour</td>
                <td>from $50.00</td>
              </tr>
              <tr>
                <td>Acrylic Removal Soak Off</td>
                <td>1 hour</td>
                <td>from $30.00</td>
              </tr>
            </tbody>
          </table>

          <br></br>
          <h3>Extras</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nail Shape per set (Coffin, Almond, Oval)</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td colSpan="2" style={{ textAlign: "left", fontWeight: "bold" }}>Length per set</td>
              </tr>
              <tr>
                <td>Medium to Long Length</td>
                <td>from $5.00 to $10.00</td>
              </tr>
              <tr>
                <td>Nail Art Free Hand</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Stickers per nail</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Rhinestones per nail</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Encapsulated Art per nail</td>
                <td>from $3.00</td>
              </tr>
              <tr>
                <td>Glitter per nail</td>
                <td>from $3.00</td>
              </tr>
              <tr>
                <td>Nail Repair</td>
                <td>from $10.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>Manicure Services</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="3" style={{ textAlign: "left", fontWeight: "bold" }}>Basic Manicure</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "left" }}>File, Cut, Buff</td>
              </tr>
              <tr>
                <td>Clear Coat</td>
                <td>20 mins</td>
                <td>$20.00</td>
              </tr>
              <tr>
                <td>Nail Polish</td>
                <td>20 mins</td>
                <td>$20.00</td>
              </tr>
              <tr>
                <td> Gel Polish</td>
                <td>30 mins</td>
                <td>from $20.00 to $30.00</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "left", fontWeight: "bold" }}>SPA Manicure</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "left" }}>Soak, File, Cut, Buff, Cuticle Maintenance, Hand Massage</td>
              </tr>
              <tr>
                <td>Clear Coat</td>
                <td>25 mins</td>
                <td>$30.00</td>
              </tr>
              <tr>
                <td>Nail Polish</td>
                <td>25 mins</td>
                <td>$35.00</td>
              </tr>
              <tr>
                <td>Gel Polish</td>
                <td>40 mins</td>
                <td>$40.00</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "left", fontWeight: "bold" }}>Deluxe SPA Manicure</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "left" }}> Soak, File, Cut, Buff, Cuticle Maintenance, Hand Scrub, Mask,
                  Massage</td>
              </tr>
              <tr>
                <td>Clear Coat</td>
                <td>35 mins</td>
                <td>$40.00</td>
              </tr>
              <tr>
                <td>Nail Polish</td>
                <td>35 mins</td>
                <td>$45.00</td>
              </tr>
              <tr>
                <td>Gel Polish</td>
                <td>60 mins</td>
                <td>$50.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>Pedicure Services</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="3" style={{ textAlign: "left", fontWeight: "bold" }}>Basic Pedicure</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "left" }}>File, Cut, Buff</td>
              </tr>
              <tr>
                <td>Clear Coat</td>
                <td>20 mins</td>
                <td>$20.00</td>
              </tr>
              <tr>
                <td>Nail Polish</td>
                <td>20 mins</td>
                <td>$25.00</td>
              </tr>
              <tr>
                <td>Gel Polish</td>
                <td>30 mins</td>
                <td>$25.00 - $30.00</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "left", fontWeight: "bold" }}>SPA Pedicure</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "left" }}>Soak, File, Cut, Buff, Cuticle Maintenance, Foot Massage</td>
              </tr>
              <tr>
                <td>Clear Coat</td>
                <td>25 mins</td>
                <td>$35.00</td>
              </tr>
              <tr>
                <td>Nail Polish</td>
                <td>25 mins</td>
                <td>$40.00</td>
              </tr>
              <tr>
                <td>Gel Polish</td>
                <td>40 mins</td>
                <td>$45.00</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "left", fontWeight: "bold" }}>Deluxe SPA Pedicure</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "left" }}>Soak, File, Cut, Buff, Cuticle Maintenance, Callus Removal, Foot Scrub, Mask, Massage </td>
              </tr>
              <tr>
                <td>Clear Coat</td>
                <td>35 mins</td>
                <td>$45.00</td>
              </tr>
              <tr>
                <td>Nail Polish</td>
                <td>35 mins</td>
                <td>$50.00</td>
              </tr>
              <tr>
                <td>Gel Polish</td>
                <td>60 mins</td>
                <td>$55.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h2>Facials</h2>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <td colSpan="3" style={{ textAlign: "left", fontWeight: "bold" }}>Pure Fiji</td>
              </tr>
              <tr>
                <th>Service</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Express Facial - (30 mins)</td>
                <td>
                  A quick cleanse, exfoliation, face massage, face steam and
                  extractions
                </td>
                <td>$35.00</td>
              </tr>
              <tr>
                <td>Deep Cleansing Facial - (1 hour)</td>
                <td>
                  Double cleanse, exfoliation, face massage, face steam and
                  extractions with the use of Vacuum suction, Face & Eye Mask
                </td>
                <td>$55.00</td>
              </tr>
              <tr>
                <td>Gold Facial (Anti-Aging) - (1 hour) KOREAN BEAUTY</td>
                <td>
                  Anti-aging facials are facials designed specifically to
                  rejuvenate mature skin. They do so by improving the thickness
                  and smoothness of skin, thereby reducing the appearance of
                  wrinkles, creases, and folds, as well as brightening up the
                  tone of the skin. Includes face cleanse, exfoliation, face
                  steam, extractions, face/eye masks and face/neck massage
                </td>
                <td>$65.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>HIMALAY PRODUCTS (INDIAN HERBAL BASED)</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Facial</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mini Facial (15 mins)</td>
                <td>
                  Facial cleanse, exfoliation, steam, extractions, and
                  moisturizer application customized to your skin type. Includes
                  removal of dead skin and whiteheads.
                </td>
                <td>$20.00</td>
              </tr>
              <tr>
                <td>Express Facial (25 mins)</td>
                <td>
                  Facial cleanse, exfoliation, steam, extractions, face massage
                  and moisturizer customized to your skin type. Includes removal
                  of dead skin, whiteheads, and blackheads.
                </td>
                <td>$25.00</td>
              </tr>
              <tr>
                <td>Deep Cleansing Facial (45 mins)</td>
                <td>
                  A double face cleanse, exfoliation, steam, masque and face
                  massage, extractions (if needed), and a moisturizer customized
                  to your skin type. Includes removal of dead skin, whiteheads,
                  and blackheads.
                </td>
                <td>$38.00</td>
              </tr>
              <tr>
                <td>Pimple/Acne Treatment (45 mins)</td>
                <td>
                  A double cleanse, deep exfoliation, steam, pimple/acne
                  treatment masque and face massage, blackheads, dead skin & zit
                  extractions, and a moisturizer customized to your skin type.
                </td>
                <td>$38.00</td>
              </tr>
              <tr>
                <td>Deep Cleansing Facial with LED LIGHT THERAPY</td>
                <td>
                  A double face cleanse, exfoliation, steam, masque and face massage, extractions (if needed), and a moisturizer customized to your skin type
                  (Includes removal of dead skin, whiteheads & blackheads)
                  LED Light Therapy for 15 minutes
                </td>
                <td>$55.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="container my-4">
          <h2 className="text-center" style={{ color: '#2c3e50' }}>Micro Hydra Facial Treatment</h2>
          <p style={{ color: 'black' }}>
            A HydraFacial is a multistep facial that involves cleansing, exfoliation, pore extractions, and the infusion of customized serums into the skin for deep hydration and antioxidant protection. This gentle facial rejuvenation treatment is safe for all skin types and tones.
          </p>
          <h4 style={{ color: 'black' }}>Benefits:</h4>
          <ul style={{ color: 'black' }}>
            <li>More even skin tone</li>
            <li>Reduced fine lines and wrinkles</li>
            <li>Smaller pores</li>
            <li>Clearer skin with less acne</li>
          </ul>

          <h4 style={{ color: 'black' }}>Procedure:</h4>
          <ol style={{ color: 'black' }}>
            <li>
              <strong>Cleanse & Peel:</strong> Use of Hydra Pen to cleanse the surface of the skin. Exfoliators reduce the dead top layer of skin cells, uncovering a more radiant-looking complexion.
            </li>
            <li>
              <strong>Extract:</strong> Suctions out blackheads and impurities from deep within your pores. This vacuum step stimulates lymphatic drainage, boosting circulation while removing excess fluid and waste. Ultrasonic scrubbers deeply extract without any damage.
            </li>
            <li>
              <strong>Hydrate:</strong> Infuses a targeted serum—a customized blend that includes hydrating ingredients, antioxidants, peptides, and collagen. This leaves the skin firmer, plumper, and reduces fine lines.
            </li>
            <li>
              <strong>Protect:</strong> Application of a hydrating mask to boost skin performance, finished with moisturizer and sunscreen.
            </li>
          </ol>

          <h4 style={{ color: 'black' }}>Duration & Pricing:</h4>
          <ul style={{ color: 'black' }}>
            <li>Standard Treatment: <strong>1 hour</strong> - <strong>$119.00</strong></li>
            <li>Add-on LED Light Therapy: <strong>1 hour 15 minutes</strong> - <strong>$129.00</strong></li>
            <li>Add-on Radio Frequency: <strong>1 hour 30 minutes</strong> - <strong>$149.00</strong></li>
          </ul>
        </div>

        <div className="container my-4">
          <h2 className="text-center" style={{ color: '#2c3e50' }}>Radio Frequency - RF Treatment</h2>
          <p style={{ color: 'black' }}>
            Radio Frequency (RF) skin tightening is a non-invasive way to achieve skin tightening and make you look younger. The device will heat the deep skin layers (dermis) of your body to produce collagen.
          </p>
          <p style={{ color: 'black' }}>
            (Includes quick cleanse, exfoliation, face massage, and face masque)
          </p>
          <h4 style={{ color: 'black' }}>Duration & Pricing:</h4>
          <ul style={{ color: 'black' }}>
            <li>Duration: <strong>35 minutes</strong></li>
            <li>Price: <strong>$39.00</strong></li>
          </ul>
        </div>

        <div className="container my-4">
          <h2 className="text-center" style={{ color: '#2c3e50' }}>LED Light Therapy</h2>
          <p style={{ color: 'black' }}>
            LED Light Therapy uses varying LED wavelengths, a skincare technique that purportedly helps treat acne, reduce inflammation, and promotes anti-aging effects.
          </p>
          <p style={{ color: 'black' }}>
            It helps with acne, pigmentation, fine lines, wrinkles, scars reduction, and overall skin rejuvenation.
          </p>
          <p style={{ color: 'black' }}>
            (Includes quick cleanse, exfoliation, face massage, and face masque)
          </p>
          <h4 style={{ color: 'black' }}>Duration & Pricing:</h4>
          <ul style={{ color: 'black' }}>
            <li>Duration: <strong>35 minutes</strong></li>
            <li>Price: <strong>$39.00</strong></li>
          </ul>
        </div>

        <div className="container my-4">
          <h2 className="text-center" style={{ color: '#2c3e50' }}>Brightening Facial</h2>
          <p style={{ color: 'black' }}>
            A brightening facial is usually recommended for dull skin or skin with uneven pigmentation. This facial typically includes a thorough exfoliation to buff away dead skin and an application of a specialist serum or cream that boosts skin radiance and rejuvenation while evening out the skin tone.
          </p>
          <p style={{ color: 'black' }}>
            (Includes Light Therapy – 10 minutes)
          </p>
          <h4 style={{ color: 'black' }}>Duration & Pricing:</h4>
          <ul style={{ color: 'black' }}>
            <li>Duration: <strong>60 minutes</strong></li>
            <li>Price: <strong>$65.00</strong></li>
          </ul>
        </div>

        <div className="container my-4">
          <h2 className="text-center" style={{ color: '#2c3e50' }}>Microshading - Powder Brows</h2>
          <p style={{ color: 'black' }}>
            Powder brows is a cosmetic semi-permanent makeup technique designed to create eyebrows with a soft powder effect, similar to powder makeup. The powder effect is achieved using a shading technique with a permanent makeup device, which is similar to a tattoo gun.
          </p>
          <h4 style={{ color: 'black' }}>Duration & Pricing:</h4>
          <ul style={{ color: 'black' }}>
            <li>Duration: <strong>2 hours</strong></li>
            <li>Price: <strong>$300.00</strong></li>
            <li>Touch up - In-house client: <strong>(1 hour)</strong> from <strong>$100.00</strong></li>
            <li>Touch up - Foreign client: <strong>(1 hour)</strong> from <strong>$150.00</strong></li>
          </ul>
        </div>

        <div className="container my-4">
          <h2 className="text-center" style={{ color: '#2c3e50' }}>Lip Neutralization</h2>
          <p style={{ color: 'black' }}>
            Lip Neutralization / Lip Lightening treatment is a unique and gradual process that works to even out lip tone and slightly brighten naturally darker lips. This procedure aims to neutralize or create an even tone across both top and bottom lips for a consistent lip tone.
          </p>
          <h4 style={{ color: 'black' }}>Duration & Pricing:</h4>
          <ul style={{ color: 'black' }}>
            <li>Duration: <strong>2 hours</strong></li>
            <li>Price: <strong>$250.00</strong></li>
            <li>Touch up - In-house client: <strong>(1 hour)</strong> from <strong>$100.00</strong></li>
            <li>Touch up - Foreign client: <strong>(1 hour)</strong> from <strong>$150.00</strong></li>
          </ul>
          <p style={{ color: 'black' }}>
            Lasts 2-4 years, or longer with recommended refresh sessions every 12-24 months.
          </p>
        </div>

        <div className="container my-4">
          <h2 className="text-center" style={{ color: '#2c3e50' }}>Make Up</h2>

          <h4 style={{ color: 'black' }}>Casual Hair & Makeup (1 Hour) from $65.00</h4>
          <ul style={{ color: 'black' }}>
            <li>Day makeup, simple look!</li>
            <li>Simple hairdo – either straight styled, half-way curls/rolls, or simple pin-ups</li>
            <li>Includes false eyelashes</li>
          </ul>

          <h4 style={{ color: 'black' }}>Casual Hair & Makeup (1 Hour) from $75.00</h4>
          <ul style={{ color: 'black' }}>
            <li>Evening makeup, glitz, and glam!</li>
            <li>Simple hairdo – either straight styled, half-way curls/rolls, or simple pin-ups</li>
            <li>Includes false eyelashes</li>
          </ul>

          <h4 style={{ color: 'black' }}>Legal Hair & Makeup (1 Hour 30 Mins) from $85.00-$100.00</h4>
          <ul style={{ color: 'black' }}>
            <li>Light bridal makeup!</li>
            <li>Hairdo – either straight styled, half-way curls/rolls, or pin-ups</li>
            <li>Includes false eyelashes</li>
          </ul>

          <h4 style={{ color: 'black' }}>Engagement Hair & Makeup (1 Hour 30 Mins) from $150.00</h4>
          <ul style={{ color: 'black' }}>
            <li>Glam bridal makeup!</li>
            <li>Hairdo – either straight styled, half-way curls/rolls, or pin-ups</li>
            <li>Includes false eyelashes</li>
          </ul>

          <h4 style={{ color: 'black' }}>Casual Makeup only (30 Mins) from $45.00</h4>
          <ul style={{ color: 'black' }}>
            <li>Day makeup, simple look!</li>
            <li>Includes false eyelashes</li>
          </ul>

          <h4 style={{ color: 'black' }}>Please Note:</h4>
          <ul style={{ color: 'black' }}>
            <li>Booking is essential, to be confirmed by the client only.</li>
            <li>50% deposit required prior to appointments.</li>
            <li>For all hairdos, make sure to wash and dry your hair before the appointment. We will NOT touch/style any oily hair.</li>
            <li>All in-house makeup – no home visits.</li>
          </ul>
        </div>

        <div className="container my-4">
          <h2 className="text-center" style={{ color: '#2c3e50' }}>Powder Brows</h2>
          <p style={{ color: 'black' }}>
            Powder brows is a cosmetic semi-permanent makeup technique designed to create eyebrows with a soft powder effect, very similar to powder makeup. The powder effect is achieved using a shading technique with a permanent makeup device, which is similar to a tattoo gun.
          </p>
          <div className="col-md-12 mb-3">
            <div className="ratio ratio-16x9">
              <img src={powder} className="img-fluid rounded" alt="Powder Brows" />
            </div>
          </div>
        </div>

      <br></br>
      </header>
      <div className="row">
        <div className="col-12 col-md-6">
          <Typography variant="h4" align="left" sx={{ mb: 5 }}>
            <span style={{ color: "#FF1493" }}>Pink Love</span>
          </Typography>
          <button
            className="btn btn-primary"
            style={{ backgroundColor: "blue" }}
            onClick={handleCallButtonClick}
          >
            Call Now
          </button>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-end">
          <a
            href="https://www.facebook.com/pinklovefiji"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "20px" }}
          >
            <FacebookIcon style={{ fontSize: "32px", color: "#3b5998" }} />
          </a>
          <a
            href="https://www.instagram.com/pinklovefiji/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "20px" }}
          >
            <InstagramIcon style={{ fontSize: "32px", color: "#C13584" }} />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ul className="nav justify-content-md-end">
            <li className="nav-item">
              <HashLink
                to="/#home"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Home
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#about"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                About
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#gallery"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Gallery
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#branches"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Branches
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#promotions"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Promotions
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#contact"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Contact
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center">
          <Typography variant="caption" color="textSecondary">
            <span>
              © Pink Love designed by Dev House ("It's a Wrap")
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default SuvaCity;
